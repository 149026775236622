import { Grid, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Helmet from "react-helmet";
import { useLocation } from "react-router-dom";
import snippet from "../assets/data/richSnippet.json";
import BannerImageTextReversedLuzyGas from "../banner/BannerImageTextReversedLuzyGas";
import HeaderBanner from "../banner/HeaderBanner";
import MobileAnchor from "../button/MobileAnchor";
import StickyC2CBottom from "../button/StickyC2CBottom";
import StickyFormMobile from "../button/StickyFormMobile";
import ComplexCardsLuzYGas from "../card/luz/ComplexCardsLuzYGas";
import RoundedPlainCardsLuzYGas from "../card/luz/RoundedPlainCardsLuzYGas";
import StickyContainer from "../container/StickyContainer";
import Footer from "../footer/Footer";
import HeaderMenuDDI from "../header/HeaderMenuDDI";
import Heading from "../heading/Heading";
import HeadingLuz from "../heading/HeadingLuz";
import QuestionsLuzYGas from "../questions/QuestionsLuzYGas";
import { useDDI } from "../utils/GetDDI";

import bannerHomeDesktop from "../assets/images/bannerLuzGas.svg";
import bannerHomeMobile from "../assets/images/bannerLuzGasMobile.svg";

function LuzYGas() {
  const { search } = useLocation();
  const ddi = useDDI();

  return (
    <>
      <Helmet>
        <title>Contrata la mejor tarifa Luz y Gas para ahorrar | Repsol</title>
        <meta
          name="description"
          content="Contrata la mejor tarifa de luz y gas con Repsol. Garantizamos el mejor precio. Te asesoramos con la tarifa que mejor se adapta a tus necesidades. Ahorra con tu nueva tarifa de Luz y Gas de Repsol"
        />
        <script type="application/ld+json">
          {JSON.stringify(snippet.luzygas)}
        </script>
      </Helmet>
      <Stack>
        <StickyFormMobile />
        <StickyC2CBottom />
        <StickyContainer>
          <HeaderMenuDDI link={`/${search}`} ddi={ddi} />
        </StickyContainer>
        <Container maxWidth="false" disableGutters>
          <HeaderBanner
            props={{
              marginStack: "10% 10% 10% 10%",
              marginStackReduced: "5% 10% 10% 10%",
            }}
            titleLine1="¿Hablamos?"
            page="luzygas"
            titleMobile="LA MEJOR TARIFA"
            titleMobile2="DE LUZ Y GAS"
            titleMobile3="Luz a precio fijo, 10 horas al día al 50% de dto."
            imageMobile={bannerHomeMobile}
            imageDesktop={bannerHomeDesktop}
          />
        </Container>
        <Container>
          <MobileAnchor />
        </Container>
        <Container maxWidth="false" disableGutters>
          <RoundedPlainCardsLuzYGas />
        </Container>
        <Container
          id="tarifas"
          container
          maxWidth="false"
          disableGutters
          sx={{ display: "flex", justifyContent: "center" }}
          alignItems="stretch"
        >
          <Grid
            item
            xs={10.5}
            md={10}
            spacing={{ xs: 1, lg: 1 }}
            sx={{ justifyContent: { xs: "center", md: "space-between" } }}
            justifyContent="space-between"
          >
            <Heading text="Hay muchas formas de ahorrar en tu factura de luz y gas, ¡elige la tuya!" />
          </Grid>
        </Container>
        <ComplexCardsLuzYGas />
        <Container maxWidth="false" disableGutters>
          <BannerImageTextReversedLuzyGas
            ddi={ddi}
            imageMobile={require("../assets/images/banner-text-luz-gas-mobile.png")}
            imageDesktop={require("../assets/images/banner-text-luz-gas.png")}
          />
        </Container>
        <Container
          container
          maxWidth="false"
          disableGutters
          sx={{
            display: "flex",
            marginBottom: "60px",
            justifyContent: "center",
          }}
          alignItems="stretch"
        >
          <Grid
            item
            xs={10.5}
            md={10}
            spacing={{ xs: 1, lg: 1 }}
            sx={{ justifyContent: { xs: "center", md: "space-between" } }}
            justifyContent="space-between"
          >
            <HeadingLuz text="No queremos que te quedes con dudas, ¡somos la solución!" />
            <QuestionsLuzYGas />
          </Grid>
        </Container>
      </Stack>
      <Footer />
    </>
  );
}

export default LuzYGas;
