import Grid from "@mui/material/Grid";
import * as React from "react";
import ComplexCard from "./ComplexCard";

const ComplexCardsLuzYGas = () => {
  return (
    <Grid
      sx={{ marginTop: "20px" }}
      container
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid
        container
        md={10}
        spacing={{ xs: 4, xl: 1 }}
        columns={{ xs: 4, sm: 8, lg: 11, xl: 12 }}
        sx={{ justifyContent: { xs: "center", xl: "space-between" } }}
        justifyContent="space-between"
      >
        <Grid
          item
          xs={3.5}
          md={6}
          lg={4}
          xl={2.75}
          display="flex"
          justifyContent="center"
        >
          <ComplexCard
            title="Ahorra a precio fijo"
            ctaText="MÁS INFO"
            descriptionBold1="Precio fijo por kWh,"
            description1="paga siempre lo mismo sin preocuparte por los horarios."
            description2="El suministro de"
            descriptionBold3=" gas, siempre al mismo precio"
          />
        </Grid>
        <Grid
          item
          xs={3.5}
          md={6}
          lg={4}
          xl={2.75}
          display="flex"
          justifyContent="center"
        >
          <ComplexCard
            title="Más de 300 horas al mes al 50%"
            ctaText="MÁS INFO"
            descriptionBold1="La luz con 50% de descuento"
            description1="las 10 horas del día que tú elijas"
            descriptionBold2="¿Y el gas? Fácil: paga SOLO"
            description2="por lo que consumes."
          />
        </Grid>
        {/*<Grid item xs={3.5} md={6} lg={4} xl={2.75} display="flex" justifyContent="center">
                    <ComplexCard title="Domingos GRA-TIS" ctaText="MÁS INFO" descriptionBold1="Todos los domingos," description1="tu consumo de luz es gratis" description2="En la factura de gas verás SOLO lo que has consumido."/>
                </Grid>*/}
        <Grid
          item
          xs={3.5}
          md={6}
          lg={4}
          xl={2.75}
          display="flex"
          justifyContent="center"
        >
          <ComplexCard
            title="100% personalizable, a tu medida"
            ctaText="MÁS INFO"
            description1="La seguridad de pagar menos en tus momentos de más consumo."
            description2="Controla en una"
            descriptionBold3=" APP en tiempo real tú factura."
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ComplexCardsLuzYGas;
